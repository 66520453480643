@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lobster&display=swap");

body {
  color: #4a4a4a !important;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.3;
  word-wrap: break-word;
  font-kerning: normal;
  -moz-font-feature-settings: "kern", "liga", "clig", "calt";
  -ms-font-feature-settings: "kern", "liga", "clig", "calt";
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
  font-feature-settings: "kern", "liga", "clig", "calt";
}

h1 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: "Lobster", cursive;
  text-rendering: optimizeLegibility;
  font-size: 2rem;
  line-height: 1.1;
}

.h1 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: "Lobster", cursive;
  text-rendering: optimizeLegibility;
  font-size: 2rem;
  line-height: 1.1;
}

h2 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: "Lobster", cursive;
  text-rendering: optimizeLegibility;
  font-size: 1.6rem;
  line-height: 1.1;
}

.h2 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: "Lobster", cursive;
  text-rendering: optimizeLegibility;
  font-size: 1.6rem;
  line-height: 1.1;
}

h3 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.25rem;
  color: inherit;
  font-family: "Lobster", cursive;
  text-rendering: optimizeLegibility;
  font-size: 1.5rem;
  line-height: 1.3;
  font-weight: 400;
}

.h3 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.25rem;
  color: inherit;
  font-family: "Lobster", cursive;
  text-rendering: optimizeLegibility;
  font-size: 1.5rem;
  line-height: 1.3;
  font-weight: 400;
}

h4 {
  margin: 0;
}

.App {
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #4a4a4a;
}

.App-link {
  color: #09d3ac;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.link {
  color: #e7498a;
  font-weight: 600;
}

.contact-list {
  padding-left: 0;
  list-style: none;
}

.contact-list li {
  margin-bottom: 0.25rem;
}

/* .main-app {
  position: relative;
  top: 210px;
  z-index: 900;
} */

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }

  /* .main-app {
    position: relative;
    top: 350px;
    z-index: 900;
  } */
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

/* @media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
} */
