.introduction-block {
  padding: 3rem;
  background-color: #ffe2e6;
  text-align: center;
  top: -2.5rem;
  border-radius: 15px;
  position: relative;
  box-shadow: 0px 2px 2px rgba(74, 74, 74, 0.8);
}

.ingress {
  font-size: 1.5rem;
}
