.portfolio-area {
  padding: 1.5rem 1rem;
  background: linear-gradient(57deg, #90ead9 50%, #fff 50%);

  .container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .content-block {
    padding: 1.5rem 1.5rem 0.5rem;
    background-color: #ffe2e6;
    border-radius: 15px;
    box-shadow: 0px 2px 2px rgba(74, 74, 74, 0.8);
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    .flex-title-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      h3 {
        margin: 0;
        font-size: 24px;
        font-weight: 600;
      }

      .icon-item {
        .show-content-btn {
          background: none;
          border: none;
          padding: 0;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: transform 0.2s ease;
          color: #666;

          &:hover {
            color: #666;
          }

          svg {
            width: 24px;
            height: 24px;
            color: #666;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .portfolio-area {
    padding: 1.5rem;
  }
}
