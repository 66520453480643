input {
  width: 90%;
  padding: 0.9rem;
  border-radius: 5px;
  margin: 0.5rem 0;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
}

textarea {
  width: 90%;
  padding: 0.9rem;
  border-radius: 5px;
  margin: 0.5rem 0;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
}

.submit-btn {
  cursor: pointer;
  color: #fff;
  border-radius: 50px;
  padding: 0.55rem 2.5rem;
  background-color: #e7498a;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: "Source Sans Pro", sans-serif;
  border: none;
  text-decoration: none;
  width: 100%;
  box-shadow: 0px 2px 2px rgba(74, 74, 74, 0.8);
}

.submit-btn:hover {
  background-color: #ffc1cb;
}

@media (min-width: 768px) {
  input {
    width: 95%;
  }

  textarea {
    width: 95%;
  }

  .btn-area {
    text-align: right;
  }

  .submit-btn {
    width: auto;
  }
}
