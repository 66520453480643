.portfolio-block {
  background: linear-gradient(108deg, #fff 45%, #ffc1cb 40%);
  padding: 2rem 1.5rem 1rem;
}

.portfolio-block .flex-right {
  color: #4a4a4a;
  text-align: right;
}

.portfolio-block .flex-row {
  display: grid;
}

.portfolio-block .image-area {
  text-align: right;
}

.portfolio-image {
  width: 60%;
  position: relative;
}

.portfolio-btn {
  color: #fff;
  background-color: #008f7b;
  border-radius: 50px;
  padding: 0.55rem 2.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: "Source Sans Pro", sans-serif;
  border: none;
  text-decoration: none;
  box-shadow: 0px 2px 2px rgba(74, 74, 74, 0.8);
}

.portfolio-btn:hover {
  background-color: #90ead9;
}

.portfolio-btn:active {
  background-color: #90ead9;
}

.large-text {
  font-size: 1.5rem;
  line-height: 1.3;
  font-weight: 400;
  font-family: "Source Sans Pro", sans-serif;
}

@media (min-width: 768px) {
  .portfolio-block {
    background: linear-gradient(110deg, #fff 50%, #ffc1cb 40%);
    padding: 4rem 0.5rem 4.5rem;
  }

  .portfolio-block .flex-row {
    display: flex;
  }

  .portfolio-block .flex-right {
    text-align: right;
    width: 60%;
  }

  .portfolio-block .flex-left {
    width: 40%;
  }

  .portfolio-block .image-area {
    text-align: right;
    padding-top: 3rem;
  }
}
