.contact-block {
  padding: 1.5rem 1.5rem;
  color: #4a4a4a;
  background: linear-gradient(61deg, #ffc1cb 45%, #90ead9 40%);
}

.contact-block .image-area {
  text-align: right;
  margin-left: auto;
  width: 70%;
}

.contact-block .flex-row {
  display: grid;
}

.contact-image {
  width: 83%;
  top: 17px;
  position: relative;
}

.contact-title {
  padding-top: 1rem;
}

.contact-btn {
  color: #e7498a;
  background-color: #fff;
  border-radius: 50px;
  padding: 0.55rem 2.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: "Source Sans Pro", sans-serif;
  border: none;
  text-decoration: none;
  box-shadow: 0px 2px 2px rgba(74, 74, 74, 0.8);
}

.contact-btn:hover {
  background-color: #90ead9;
}

.contact-btn:active {
  background-color: #90ead9;
}

.large-text {
  font-size: 1.5rem;
  line-height: 1.3;
  font-weight: 400;
  margin-bottom: 2rem;
  font-family: "Source Sans Pro", sans-serif;
}

@media (min-width: 768px) {
  .contact-block .flex-row {
    display: flex;
    padding-right: 1.5rem;
  }

  .contact-block {
    padding: 4rem 1.5rem 4.5rem;
    background: linear-gradient(65deg, #ffc1cb 45%, #90ead9 40%);
  }
}
