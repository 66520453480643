.show-content-photos {
  margin-bottom: 1.5rem;
}

.row {
  display: grid;
}

.imagebox {
  background-color: #fff;
  padding: 0.5rem 0.5rem 0.25rem;
  box-shadow: 0px 2px 2px rgba(74, 74, 74, 0.8);
  margin: 0.5rem;
  width: 90%;
}

.imagebox img {
  width: 100%;
}

// .intrinsic-item {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
// }

/* Animations */
.animated {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@media (min-width: 767px) {
  .row {
    display: flex;
  }
}
