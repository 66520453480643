.contact-area {
  padding: 1rem;
  background: linear-gradient(57deg, #90ead9 50%, #fff 50%);
}

.contact-area .image-cropper {
  position: relative;
  overflow: hidden;
  max-width: 200px;
  max-height: 200px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  z-index: 900;
  margin-bottom: 2rem;
  box-shadow: 0px 2px 2px #4a4a4a;
}

.contact-area .flex-row {
  display: block;
}

.contact-area .info-details {
  display: block;
}

.contact-form-area {
  width: 100%;
}

.contact-area .flex-item-left {
  width: 100%;
  padding: 0.5rem;
}

.contact-area .flex-item-right {
  padding: 0.5rem;
}

@media (min-width: 536px) {
  .contact-area .info-details {
    display: flex;
  }
}

@media (min-width: 768px) {
  .contact-area {
    background: linear-gradient(119deg, #fff 40%, #90ead9 40%);
  }

  .contact-area .flex-row {
    display: flex;
  }

  .contact-area .info-details {
    display: block;
  }

  .contact-area .flex-item-left {
    width: 30%;
    padding: 2rem;
  }

  .contact-form-area {
    padding-top: 2rem;
    width: 80%;
    margin-left: auto;
  }

  .contact-area .flex-item-right {
    padding: 2rem;
    width: 70%;
  }
}
