.cv-area {
  padding: 1.5rem 1rem;
  background: linear-gradient(57deg, #90ead9 50%, #fff 50%);
  overflow-x: hidden;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    overflow-x: hidden;
  }

  .flex-row {
    display: grid;
    overflow-x: hidden;

    @media (min-width: 768px) {
      display: flex;
    }
  }

  .flex-item-left {
    padding: 0.5rem;
    width: 100%;
    overflow-x: hidden;

    @media (min-width: 768px) {
      padding: 2rem;
      width: 30%;
    }
  }

  .flex-item-right {
    width: 100%;
    overflow-x: hidden;

    @media (min-width: 768px) {
      padding: 2rem;
      width: 70%;
    }
  }

  .image-cropper {
    position: relative;
    overflow: hidden;
    max-width: 200px;
    max-height: 200px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    z-index: 900;
    margin-bottom: 2rem;
    box-shadow: 0px 2px 2px #4a4a4a;
  }

  .header-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .profile-title {
    text-align: left;
    margin-bottom: 0.5rem;
    word-break: break-word;
    overflow-wrap: break-word;
  }

  .contact-list {
    list-style: none;
    padding: 0;
    margin: 1rem 0 1.5rem;
    word-break: break-word;
    overflow-wrap: break-word;

    li {
      margin-bottom: 0.25rem;
      text-align: left;
      word-break: break-word;
      overflow-wrap: break-word;

      &:last-child {
        margin-bottom: 0;
      }

      .link {
        color: #e7498a;
        font-weight: 600;
        text-decoration: none;
        transition: color 0.2s ease;
        word-break: break-word;
        overflow-wrap: break-word;

        &:hover {
          color: #666;
        }
      }
    }
  }

  .content-block {
    padding: 1.5rem 1.5rem 0.5rem;
    background-color: #ffe2e6;
    border-radius: 15px;
    box-shadow: 0px 2px 2px rgba(74, 74, 74, 0.8);
    margin-bottom: 1.5rem;
    word-break: break-word;
    overflow-wrap: break-word;

    &:last-child {
      margin-bottom: 0;
    }

    .flex-title-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      word-break: break-word;
      overflow-wrap: break-word;

      h3 {
        margin: 0;
        font-size: 24px;
        font-weight: 600;
        word-break: break-word;
        overflow-wrap: break-word;
      }

      .icon-item {
        .show-content-btn {
          background: none;
          border: none;
          padding: 0;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: transform 0.2s ease;
          color: #666;

          &:hover {
            color: #666;
          }

          svg {
            width: 24px;
            height: 24px;
            color: #666;
          }
        }
      }
    }
  }
}
