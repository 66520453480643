.cv-block {
  padding: 0 1.5rem 1.5rem 1.5rem;
  color: #4a4a4a;
  background: linear-gradient(65deg, #90ead9 45%, #fff 40%);
}

.cv-block .image-area {
  text-align: right;
  margin-left: auto;
  width: 70%;
}

.cv-block .flex-row {
  display: grid;
}

.cv-image {
  width: 83%;
  top: -5px;
  position: relative;
}

.cv-title {
  padding-top: 1rem;
}

.cv-btn {
  color: #fff;
  border-radius: 50px;
  padding: 0.55rem 2.5rem;
  background-color: #e7498a;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: "Source Sans Pro", sans-serif;
  border: none;
  text-decoration: none;
  box-shadow: 0px 2px 2px rgba(74, 74, 74, 0.8);
}

.cv-btn:hover {
  background-color: #ffc1cb;
}

.cv-btn:active {
  background-color: #ffc1cb;
}

.large-text {
  font-size: 1.5rem;
  line-height: 1.3;
  font-weight: 400;
  font-family: "Source Sans Pro", sans-serif;
}

@media (min-width: 768px) {
  .cv-block .flex-row {
    display: flex;
    padding-right: 1.5rem;
  }

  .cv-block {
    padding: 0 0.5rem 4.5rem;
    background: linear-gradient(57deg, #90ead9 50%, #fff 50%);
  }

  .cv-block .flex-right {
    text-align: right;
    width: 50%;
  }

  .cv-block .flex-left {
    width: 50%;
    padding-top: 2rem;
  }

  .cv-block .image-area {
    width: 100%;
  }

  .cv-image {
    width: 80%;
    left: 3rem;
    top: 17px;
    padding: 0 0 2rem 2rem;
    position: relative;
  }
}
