.expandable-content {
  margin-bottom: 2rem;

  .expandable-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    h4 {
      margin: 0;
      font-size: 24px;
      font-weight: 600;
    }

    button {
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      color: #666;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 0.2s ease;

      &:hover {
        color: #333;
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
